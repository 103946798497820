<template>
  <div id="app">
    <header id="home">
      <div class="container clearfix">
        <div class="logo fl">
          <img src="./assets/logo.png" height="43" alt="metacraft">
        </div>
        <nav class="fl">
          <ul>
            <li>Home</li>
            <li @click="scrollTo('intro')">Introduction</li>
            <li @click="scrollTo('tinfo')">Token</li>
            <li @click="scrollTo('prof')">Profession</li>
            <li @click="scrollTo('roadmap')">Roadmap</li>
            <li><a href="https://github-metacraft.gitbook.io/metacraft/" target="_blank">Gitbook</a></li>
          </ul>
        </nav>

        <div class="contact fr">
          <span>Contact</span>
          <ul>
            <li><a href="https://twitter.com/MetaCraftEN" target="_blank">Twitter</a></li>
            <li><a href="https://t.me/MetaCraftGame" target="_blank">Telegram</a></li>
          </ul>
        </div>

        <!-- <a href="https://app.metacraft.game" target="_blank" class="launch">Launch App</a> -->
        <br style="clear:both">
      </div>
    </header>

    <div class="banner">
      <!-- <div class="container">
      <ul>
        <li><a href="https://pancakeswap.finance/swap?outputCurrency=0xdF677713a2C661ECD0b2BD4d7485170Aa8c1ECeB" target="_blank"><img src="./assets/buy.png" width="200" alt=""></a></li>
        <li><a href="https://app.metacraft.game" target="_blank"><img src="./assets/play.png" width="200" alt=""></a></li>
      </ul>
      </div> -->
    </div>

    <section class="intro" id="intro">
      <h1>Introduction</h1>
      <div class="container content">
        <p>MetaCraft is a running group chain play imitating Rarity. In the future, MetaCraft will fork to create a meta universe game. For example: mapping on public chains such as BSC and ETH, allowing players to play on any chain; introducing social attributes to give each player a unique NFT identity. </p>

        <p>The game’s story, background, settings, newly added quest characters, and multiple open endings will all be jointly formulated by community members.</p>

        <p>When MetaCraft develops to a certain scale, we will visualize the game by adding a variety of animations and scenes, and MetaCraft will become an amazing production.</p>

        <p>In the fantasy world of swords and magic, many races peaceful lives in the mainland, until that day, a city appears in the sky, people call it to - Skycity. With the emergence of Sky City, there have been many caves on the mainland, and there is a treasure of the excitement of the adventurers and the murder of the people. The legendary, Sky City has the largest treasure and the strongest monster-dragon.</p>

        <p>Where did Sky City come from? What is the biggest treasure? Does the dragon really exist? Brave adventurers, build a unique NFT role to explore their own unique NFT characters.</p>
      </div>
    </section>

    <section class="mecha" id="mecha">
      <h1>Game Mechanics</h1>
      <ul class="container content">
        <li>
          <img src="./assets/mecha1.png"  width="280" alt="">
        </li>
        <li>
          <img src="./assets/mecha2.png"  width="262" alt="">
        </li>
        <li>
          <img src="./assets/mecha3.png"  width="262" alt="">
        </li>
        <li>
          <img src="./assets/mecha4.png"  width="262" alt="">
        </li>
      </ul>
    </section>

    <section class="tinfo"  id="tinfo">
      <h1>Token Information</h1>
      <ul class="container content">
        <li>
          <img src="./assets/tinfo.png" width="645" alt="">
        </li>
        <li class="distrib">
          <h2>MCT</h2>
          <dl>
            <dt>Distributed:</dt>
            <dd><em>Total:</em><span>1,641,963,300</span></dd>
            <dd><em>Ecosystem Fund:</em><span>328,000,000 (20%)</span></dd>
            <dd><em>Private Round:</em><span>16,420,000（1%）</span></dd>
            <dd><em>Advisor:</em><span>16,420,000（1%）</span></dd>
            <dd><em>Community Event:</em><span>49,000,000（3%）</span></dd>
            <dd><em>Team:</em><span>246,300,000 (15%) </span></dd>
            <dd><em>Play to Earn:</em><span>985,823,300 (60%)</span></dd>
            <dd>
              <b>Address:</b><br>
              <span>0xdF677713a2C661ECD0b2BD4d7485170Aa8c1ECeB</span>
            </dd>
          </dl>

          <h2>Gold</h2>
          <dl>
            <dt>All obtained through hero work</dt>
            <dd>
              <b>Address:</b><br>
              <!-- <span>0x60EFbC32d2D4bA99e90a20a82D37308C1903DA88</span> -->
            </dd>
          </dl>
        </li>
      </ul>
    </section>

    <section class="prof" id="prof">
      <h1>Profession</h1>
      <ul class="container">
        <li v-for="prof in profession" v-bind:key="prof.name">
          <div class="upper clearfix" @click="prof.fold=!prof.fold">
            <div class="avatar fl">
              <img :src="prof.avatar" height="38" :alt="prof.name">
            </div>
            <p class="name fl">{{prof.name}}</p>
            <p class="arr-btn fr"><i class="arrow" :class="[prof.fold?'up':'down']"></i></p>
          </div>

          <div class="lower" v-show="prof.fold">
            <div class="intro" v-html="prof.intro"></div>
          </div>
        </li>
      </ul>
    </section>

    <section class="attr" id="attr">
      <h1>Attributes</h1>
      <ul class="container">
        <li v-for="attr in attributes" v-bind:key="attr.title">
          <div class="upper clearfix" @click="attr.fold=!attr.fold">
            <p class="name fl" style="margin-left:24px">{{attr.title}}</p>
            <p class="arr-btn fr"><i class="arrow" :class="[attr.fold?'up':'down']"></i></p>
          </div>

          <div class="lower" v-show="attr.fold">
            <div class="intro" v-html="attr.desc"></div>
          </div>
        </li>
      </ul>
    </section>

    <section class="roadmap" id="roadmap">
      <h1>Roadmap</h1>
      <div class="container">
        <img src="./assets/roadmap.png" width="935" alt="">
      </div>
    </section>

    <footer>
      <div class="container">
        <img src="./assets/logo.png" height="43" alt="metacraft">
        <ul>
          <li>
            <a href="https://t.me/MetaCraftGame" target="_blank"><img src="./assets/tele.png" width="40" alt=""></a>
          </li>
          <li>
            <a href="https://twitter.com/MetaCraftEN" target="_blank"><img src="./assets/twitter.png" width="40" alt=""></a>
          </li>
        </ul>
      </div>
    </footer>

    <a class="backtop" href="javascript:;" v-show="showTop" @click="scrollTo('home')">
        <img src="./assets/totop.png" height="40">
    </a>
    <announcement />
  </div>
</template>

<script>
import rdata from './rdata.js';
import Announcement from './components/Announcement.vue';

export default {
  name: 'App',
  components: {
    Announcement
  },
  data() {
		return {
      profession: rdata.prof,
      attributes: rdata.attr,
      showTop: false
    }
  },
  mounted() {
    window.addEventListener("scroll",()=>{
        let scrollTop = document.documentElement.scrollTop;
        if(scrollTop > 100){
            this.showTop = true;
        }else{
            this.showTop = false;
        }
    }) 
  },
  methods: {
    scrollTo(id) {
      window.document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #E3E1DF;
}

.container {
  width: 1180px;
  margin: 0 auto;
  position: relative;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.arrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  &.up {
    transform: rotate(135deg);
  }
  &.down {
    transform: rotate(-45deg);
    margin-top: -20px;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  width: 100%;
  height: 100px;
  background-color: #000;
  .logo {
    height: 100px;
    display: flex;
    align-items: center;
  }
  nav {
    color: #fff;
    font-size: 20px;
    margin-left: 32px;
    line-height: 100px;
    ul {
      display: flex;
      li {
        flex: 1;
        margin-left: 40px;
        position: relative;
        cursor: pointer;
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 20px;
          margin-left: -40px;
          width: 80px;
          height: 20px;
          background-image: url(./assets/nav.png);
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity 0.5s;
        }
        &:hover::after {
          opacity: 1;
        }
        a {
          color: #fff;
        }
      }
    }
  }
  .contact {
    color: #fff;
    position: relative;
    line-height: 100px;
    font-size: 20px;
    ul {
      position: absolute;
      transition: opacity .5s;
      left: 0;
      top: 72%;
      opacity: 0;
      padding: 0 12px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.27); 
      z-index: 100; 
      li {
        line-height: 3;
        &:first-child {
          border-bottom: 1px solid rgba(255, 255, 255, 0.27);
        }
        a {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    &:hover ul{
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      left:120%;
      bottom: 47%;
      width: 8px;
      height: 8px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-45deg);
    }
  }
  .launch {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 20px auto;
    left: 108%;
    width: 90px;
    height: 28px;
    line-height: 28px;
    border-radius: 6px;
    border: 1px solid #fff;
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
  }
}

.banner {
  width: 100%;
  height: 517px;
  background-image: url(./assets/banner.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #5E5340;
  div {
    height: 100%;
    ul {
      display: flex;
      position: absolute;
      right: -30px;
      bottom: 50px;
      li {
        flex: 1;
        margin-left: 20px;
      }
    }
  }
}

section {
  border-top: 5px solid #5E5340 !important;
  h1 {
    width: 100%;
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 28px;
    line-height: 72px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .content {
    padding: 32px 12px;
    line-height: 1.5;
    p {
     margin-top: 20px; 
    }
  }

  &.roadmap {
    div {
      text-align: center;
      padding: 70px 0;
    }
  }

  &.mecha {
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      li {
        flex: 1;
        text-align: center;
        &::after {
          display: block;
          position: relative;
          top: -36px;
          font-weight: bolder;
        }
        &:nth-child(1)::after {
          content: 'Dungeon challenge';
        }
        &:nth-child(2)::after {
          content: 'Daily mining';
        }
        &:nth-child(3)::after {
          content: 'Team fight';
        }
        &:nth-child(4)::after {
          content: 'Role fusion';
        }
      }
    }
  }

  &.tinfo {
    ul {
      display: flex;
      margin-bottom: 30px; 
      li {
        flex: 1;
        &:first-child  {
          padding-top: 100px;
        }
        &.distrib {
          margin-left: 30px;
          h2 {
            font-size: 28px;
            font-weight: bolder;
            color: #000;
            line-height: 52px;
            text-indent: 20px;
            margin-top: 20px;
          }
          dl {
            background: rgba(255, 255, 255, 0.18);
            border-radius: 8px;
            border: 1px solid #C1BEBB;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            dt {
              line-height: 2;
            }
            dd {
              margin-top: 20px;
              em {
                display: inline-block;
                width: 50%;
              }
              b {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  &.prof, &.attr {
    li {
      width: 100%;
      background: rgba(255, 255, 255, 0.18);
      border-radius: 16px;
      border: 1px solid #C1BEBB;
      margin: 24px 0;
      .upper {
        height: 68px;
        cursor: pointer;
        .avatar {
          height: 68px;
          display: flex;
          align-items: center;
          margin-left: 20px;
        }
        .name {
          line-height: 68px;
          margin-left: 12px;
          font-size: 24px;
          font-weight: bolder;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        .arr-btn {
          position: relative;
          top: 30px;
          left: -30px;
        }
      }

      .lower {
        .intro {
          padding: 12px 24px;
            p {
              margin-bottom: 20px; 
              line-height: 1.5;
              b{
                font-weight: bolder;
              }
            }
        }
      }
    }
  }
}

footer {
  border-top: 5px solid #5E5340 !important;
  width: 100%;
  height: 100px;
  background-color: #000;
  padding-top: 32px !important;
  ul {
    float:right;
    display: flex;
    li {
      flex: 1;
      margin-left: 40px;
    }
  }
}

.backtop {
  position: fixed;
  right:15px;
  bottom:164px;
}

@media only screen and (max-width: 1440px) {
    .banner {
        background-size:cover;
    }
}
</style>
