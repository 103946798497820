export default {
    prof: [
        {
          avatar: require('./assets/barbarian.png'),
          name: 'Barbarian',
          fold: 0,
          intro: `<p>From the frozen northern wilderness to the hellish southern rainforest, there are brave and powerful fighters. Civilized people call them savages or berserkers, thinking they represent destruction, disrespect, and cruelty. But these "barbarians" have proven their courage and perseverance to their allies. They are shrewd and stubborn, and they are merciless, and they can't be taken lightly by anyone.</p>
          <p><b>Adventure:</b>The best way for a barbarian to gain a place in a civilized society is to take an adventure. They don't like to be boring guard jobs or mundane errands. Barbarians are accustomed to the danger and uncertainty of adventurous activities, and are not afraid of hard work. Barbarians also like to participate in combat missions. They hate unnatural things, including undead creatures, and demons.</p>
          <p><b>Traits:</b>Barbarians are outstanding fighters. Compared with the average warrior who relies on good training, the barbarian relies on the powerful and violent amount of talent. When caught in a violent state, they will become more powerful and invincible. They will become very tired after the rage, so the barbarians can rage a limited number of times in a day, but this is enough. Barbarians are as comfortable in the wilderness as at home, and can run at high speeds.</p>
          <p><b>Camp:</b>Barbarians cannot be a lawful camp. They may have a sense of honor, but their hearts are wild and unruly. This wildness is the source of their strength, which is incompatible with lawfulness. The barbarians of the chaotic camp, sometimes free and unrestrained, sometimes become a source of destructive thoughtlessness.</p>
          <p><b>Religion:</b>Some savages do not participate in religion, but like to maintain an intuitive and natural relationship with the heavens and the earth. Some believe in deities with powerful powers, such as the god of power Cod, the god of nature Obai Hai, and the god of killing Ethnur. Barbarians may sacrifice their lives for their faith.</p>
          <p><b>Background:</b>Barbarians come from uncivilized areas or barbarian tribes on the margins of civilization. Barbarian adventurers may come to town because of the temptation of wealth, or they may have been caught as a slave from their hometown but escaped, or they may come to serve as a mercenary, or because their hometown has been invaded and fled into exile. Barbarians have nothing to do with each other unless they are from the same tribe or region. In fact, they do not think of themselves as barbarians, but as fighters. Race: Most human barbarians come from the wild, while half-orc barbarians leave the orc tribe and come to human society. Dwarf barbarians are less common, usually from the dwarven kingdom that has fought for many years with goblins, orcs, and giants, which led to the decline. Barbarians of other races are more rare.</p>
          <p><b>Race:</b>Among the rough humanoid races (such as: orcs and ogres), there are usually more barbarians than warriors.</p>
          <p><b>Relationship with other professions:</b>Because barbarians are wild people, they get along well with druids, rangers, and priests who believe in natural gods (such as Obai Hai or Elona). Many barbarians also appreciate the talent of bards and love music. Barbarians don't like things they don't understand. Magic is a good example. Barbarians are more tolerant of warlocks than wizards, but this may be because they are more attractive. The monk advocates penance and combat discipline, and it is usually difficult to face the barbarians head-on, but this does not mean that the two professions are bound to be hostile to each other. Barbarians have no special perception of paladins, warriors, priests, and wanderers.</p>`
        },
        {
          avatar: require('./assets/bard.png'),
          name: 'Bard',
          fold: 0,
          intro: `<p>It is said that music has a special magic, and the bard proves it. The bard wanders around, collecting various local legends and telling them everywhere. He uses music to perform magic, so that the audience may cry from time to time and joy from time to time. This is the life of singing everywhere. Bards must face all kinds of people, so they often become diplomats, coordinators, couriers, spies, or spies.</p>

          <p>The bard’s magic comes from the heart. A kind-hearted bard can bring hope and courage to the unfortunate, and use his good music magic to fight against the evil tricks. When encountering corrupt local lords, the kind bard will resist tyranny, evade hunts skillfully, and inspire the courage of the oppressed people. But people with evil hearts can also play music. Evil bards don't like to use violence, but use superb skills to manipulate the hearts of others, and collect their "voluntary" gifts from obsessed audiences.</p>

          <p><b>Adventure:</b>The bard sees adventure as an opportunity to learn. They use their skills and abilities to enter mysterious ancient tombs, search for lost music, interpret ancient books, or travel to other places, explore rare birds and animals, and learn new songs and stories. Bards like to go with heroes (or demon heads) in order to get first-hand material. If a bard can tell magical stories based on personal experience, he can usually get praise from his peers. The bards continue to tell many heroic deeds, and the scenes in the story are deeply rooted in the hearts of many bards, making them consider themselves heroes.</p>
          <p><b>Traits:</b>The bard’s magic comes from the soul, not from the spellbook. He can only cast a few spells, but he doesn't need to prepare in advance. The magic of a wizard or sorcerer can burst power, but the magic of a bard emphasizes charm and illusion. In addition to spells, the music and songs of bards also contain magic. He can inspire his companions, fascinate the audience, or counteract spells cast by voice. Bards have some rogue skills, but their skills are not as specialized as rogues. Because bards like to listen to stories, they have a special knowledge of local legends and special objects.</p>
          <p><b>Camp:</b>The bard wanders around, acting on his own intuition and inspiration, not tradition or law. The genius, magic power, and lifestyle of the bard cannot coexist with the lawful camp.</p>
          <p><b>Religion:</b>The bard respected and loved Fran, the god of the road. They sometimes stay at the Altar of Fran, hoping to make some donations from the travelers. Although many bards are not elves, they still worship Sylvester, the god of elves, because he is the father of music and poetry. Good bards also prefer the god of honor, believing that he can take care of their journey. A bard who likes chaos and stealing will respect Oulidamara, the god of thieves. The evil bards believe in Erisnu, the god of killing, but they rarely admit it. Because the bards spend a lot of time traveling, although they have faith, they rarely visit the temple regularly.</p>
          <p><b>Background:</b>The bard apprentice usually learns skills from an experienced bard and follows his mentor until he is able to start his own journey. Many bards were orphans or vagrants when they were young, and were cared for by their tutors. Since bards often gather in informal "colleges", it is easy to meet well-known colleagues in the region. However, the bard does not have a strong sense of community. In fact, many bards have a strong competitive attitude with each other, jealous of each other's reputation, and each protect their own sphere of influence.</p>
          <p><b>Race:</b>Bards are usually humans, elves, or half-elves. Human beings are very accustomed to wandering life and can easily adapt to new environments. Elves are gifted with music and magic, so the profession of a bard is natural for them. The wandering life of bards is also very suitable for half-elves, after all, they feel strange even in their homeland. Among dwarves, dwarfs, and halflings, bards are rarely seen, but as long as there is a teacher who is willing to train, members of these races may still become bards.

          Except for the centaur, there are almost no bards in the savage humanoid race. Centaur bards sometimes train children of humans or other races.</p>
          <p><b>Relationship with other professions:</b>The bard cooperates well with partners of other professions. The bard is usually the spokesperson of the team, using social skills to benefit the team. If the team does not have a wizard or sorcerer, it needs to rely on the bard's spells; if it lacks a wanderer, it can use the bard's skills. Bards are curious about the techniques of more focused adventurers, and often try to get advice from warriors, warlocks, and rogues.</p>`
        },
        {
          avatar: require('./assets/cleric.png'),
          name: 'Cleric',
          fold: 0,
          intro: `<p>The creation of the gods exists in the beautiful scenery, in the mighty military appearance, in the magnificent temple, and in the hearts of believers. Like human beings, the gods are kind, vicious, conservative, tyrannical, simple, and incomprehensible. However, deities usually exercise their will through intermediaries, who are pastors who believe in them. Good pastors will heal, protect others, and maintain justice; evil pastors will looting, destroying, and subverting everything. The pastor will use the power of faith to practice the will of God, and may also use the power of the deity to improve his own destiny.</p>

          <p><b>Adventure:</b>In principle, the pastor joins the adventure in order to achieve God's goal, at least broadly speaking. For example, a kind pastor will help those in need. He would hope to build a good reputation for God and the temple through noble behavior. The evil priest will try to increase the power of the deity and himself, making people fearful and respectable.

          Pastors sometimes receive orders (or at least suggestions) from church superiors, instructing them to complete tasks assigned by the church. He and his partner can often receive reasonable rewards, and the church usually generously rewards them with spells or sacred objects. Of course, pastors are also humans, so their motivation to take risks may be no different from ordinary people.</p>
          <p><b>Traits:</b>Priests are good at divine arts, and these spells can be used for healing. Even novice pastors can save people who are dying, and experienced pastors can even bring them back to life.

          As the leader of the divine power, the priest can dispel or even destroy the undead. In contrast, evil priests can use undead creatures for their own use.
          
          The priest has some combat training and can use simple weapons. They also receive training in the use of armor, after all, when they cast magic spells, they are not affected by armor like arcane spells.</p>

          <p><b>Camp:</b>Just like a deity, the pastor can be of any faction. Since people are more willing to believe in good gods, the number of good pastors is far greater than that of evil ones. Pastors are usually closer to lawful rather than chaotic camps, because lawful religions are generally more organized than chaotic religions and are better able to recruit and train new people. Usually the pastor’s camp is the same as the deity he believes in, although some people may not be exactly the same. For example, most priests who believe in the God of Heroes Hyranius (lawful good) are also lawful and kind, but there are still some priests of the God of Heroes who are lawful neutral or neutral. In addition, unless the deity believes in absolutely neutral, the priest cannot choose the absolute neutral camp. For example, the priest of the holy cusp of punishment (lawful neutral) can only be lawful kind or lawful neutral.</p>

          <p><b>Religion:</b>Common deities have pastors who follow. Human priests in civilized areas most often believe in the sun god Peiro, and priests of non-human races usually believe in the main god of that race.

          Some pastors do not believe in specific gods, they serve the source of divine power. These priests have the same magical abilities as other priests who believe in deities, but they do not participate in specific religious organizations or sects. For example, a pastor who believes in kindness and lawfulness can get along well with a pastor who believes in lawful and kind gods and praises lawfulness and kindness in life, but he is not a member of the church organization.</p>

          <p><b>Background:</b>Most pastors are publicly appointed by religious organizations (usually called churches), and they all have to swear to uphold the ideals of the church. Most pastors join the church in their youth. Although some people have dedicated themselves to the gods they believe in earlier, a small number of them are "inspired" by God only in the future. Some pastors follow the daily routine of the church, while others adopt a more liberal lifestyle, as long as they can respond to the will of the deity.

          Pastors of the same religion should get along well with each other, although in fact there may also be struggles within sects, which is more embarrassing than the confrontation between different religions. Pastors with the same ideals (such as kindness and lawfulness) usually have the same purpose, believing that the other party is an alliance that transcends religious organizations, but pastors with opposite ideals are each other's enemies. In civilized areas, open religious wars only occur during civil wars or social riots, but malicious political struggles often occur between hostile religions.</p>

          <p><b>Race:</b>All common races have priests, because religious and spiritual needs are common. However, priests of many races usually concentrate on religious affairs rather than taking risks. Priests who take part in holy wars or adventures are usually humans or dwarves.

          The barbaric humanoid races rarely see priests, except for the war lizards. They are good at divine arts, and under the leadership of the priests in the clan, they will sacrifice their captives to the cannibal ritual.</p>

          <p><b>Relationship with other professions:</b>In the adventure team, the priest is everyone's friend, and is usually the key to team cohesion. The priest uses the divine power to provide medical treatment to heal other adventurers. The priest sometimes conflicts with the druid, because the druid admires the more ancient and primitive divine power. Usually the pastor’s religion affects how he gets along with others. For example, the pastor of Olida Mara, the god of thieves, can get along well with wanderers, but if it is the pastor of Hyranius, the god of brave, Will be dissatisfied with these partners.</p>`
        },
        {
          avatar: require('./assets/druid.png'),
          name: 'Druid',
          fold: 0,
          intro: `<p>The fury of the storm, the soft intensity of the sun, the cunning of the fox, the power of the bear-all these and more are driven by the druid. But Druid does not advocate controlling nature. They think that such claims are rhetoric of city dwellers. The power of Druids does not come from the domination of nature, but from harmony with nature. For those who invaded the sacred woodland of the Druid and experienced the wrath of the Druid, the difference here is too subtle.</p>

          <p><b>Adventure:</b>Druids take risks in order to gain knowledge—especially knowledge about animals and plants that they are not familiar with—and power. Sometimes, their superiors will ask them to help. Druids may also use their power to fight against those who threaten their beloved-often including towering old trees or uninhabited mountains more than people. Although druids accept the horror and cruelty of nature, they hate those that are unnatural, including alien creatures (such as beholders and scavengers) and undead creatures (such as zombies and vampires). They sometimes lead attacks on these creatures, especially when these creatures invade the druid's jurisdiction.</p>
          <p><b>Traits:</b>Druids cast divine spells in much the same way as priests, but their spells are derived from the forces of nature rather than from the gods. These spells are oriented towards nature and animals. In addition to spells, as experience grows, the druid gains a series of increasing magical powers, including the ability to transform into animals. The weapons and armor that druids can use are limited by traditional vows and not just by training. A druid can learn to use a two-handed sword, but using it will violate the druid's oath and inhibit her druid power. Druids avoid carrying large amounts of processed metals because they will hinder the pure, primitive nature that Druids strive to embody.</p>

          <p><b>Camp:</b>Druids who are consistent with the ultimate indifference of nature must maintain at least some degree of impartiality. In other words, even if they are not truly neutral, they must remain neutral in some respects. Just as nature includes the opposition of life and death, beauty and terror, peace and violence, two druids can have distinct or even opposite camps (such as neutral good and neutral evil) and still belong to the same Druid tradition.</p>

          <p><b>Religion:</b>Druids respect nature and derive magic power from the power of nature itself or a natural god. They usually pursue a super yet mysterious spirituality that is integrated with nature, rather than a belief in a certain divine entity. But there are still some druids who venerate or at least respect Obohai, the god of nature, or Elona, the goddess of the woods.</p>

          <p><b>Background:</b>Although most outsiders who think druids are withdrawn do not understand the druid organization, all druids are members of a society that transcends political boundaries and spreads everywhere. People who want to become druids are accepted by this society through mysterious rituals, which also include trials that not everyone can survive. Only when a certain druid possesses a certain level of abilities is he allowed to go out alone. All druids are nominally members of the druid society, although some druids are isolated from the world to the point that they have never seen high-level members or participated in druid gatherings. All druids recognize each other as brothers and sisters. But like real wild animals, druids sometimes compete with each other and even prey on each other. Druids are sometimes asked to serve high-level druids, but these tasks will be appropriately paid. Similarly, a low-level druid can also ask for the help of a high-level brother, and exchange money or services fairly. Druids may live in small towns, but spend most of their time in wild areas. Even at a glance, there are often druid woodlands near large cities surrounded by arable land—a small, natural shelter where druids live and carefully protect it. The shelter next to a coastal city is often an isolated island adjacent to the city, where the druids find the isolation they want.</p>

          <p><b>Race:</b>Elves and gnomes are closely related to the natural zone and often become druids. Humans and half-elves often become druids, and druids are especially common among uncivilized humans. Dwarves, halflings, and half-orcs rarely become druids. A few members of the cruel humanoid race were taken into the druid society, especially the jackals often have a considerable number of evil druids. Jackal druids can be accepted by druids of other races, even if they are unpopular.</p>

          <p><b>Relationship with other professions:</b>Druids and rangers and many barbarians have the same reverence for nature and familiarity with nature. Druids do not like the paladin's devotion to abstract ideals rather than the "real world". They don't quite understand the urban style that rogues often have, and feel that arcane spells cause chaos and are a bit annoying. But Druids will not be so good even if they do not accept these differences. They rarely attack others, even those who are very different from themselves.</p>`
        },
        {
          avatar: require('./assets/fighters.png'),
          name: 'Fighters',
          fold: 0,
          intro: `<p>The knights in search, the high lords who conquered everywhere, the king's warriors, the elite infantry, the determined mercenaries and bandit leaders-they are all fighters. A fighter may be a staunch protector who saves people from danger, or a cruel predator or a brave adventurer. Some of them have the kindest souls in the world, and they originally intended to sacrifice themselves for the benefit of more people. Others belong to the worst group, killing for personal gain-even for entertainment-without guilt. A fighter who is not active in adventure may be a soldier, guard, bodyguard, defender, or killer of a criminal organization. An adventurous fighter may call himself a warrior, a mercenary, a thug, or simply call himself an adventurer.</p>

          <p><b>Adventure:</b>Most fighters see adventure, assault, and dangerous people as their job. Some of them have patrons who are paid regularly and quantified. Others like to live the life of a prospector, taking huge risks to catch big fish. Some fighters who are more concerned about people's livelihood use their fighting skills to protect those who are in danger and cannot defend themselves. But regardless of their initial motives, fighters often end their peaceful lives in order to fight and take risks.</p>

          <p><b>Traits:</b>Of all professions, fighters have the most comprehensive fighting abilities (hence their name). Fighters are familiar with all common weapons and armors. In addition to comprehensive fighting skills, each fighter develops his own specialties. One fighter may be particularly good at using a certain weapon, and another may be trained to perform special and complex fighting tricks. With the accumulation of experience, fighters have more opportunities to develop their fighting skills. With the ability to specialize in fighting moves, fighters can master the most complex fighting tricks relatively quickly.</p>

          <p><b>Camp:</b>Fighters can be of any faction. The good fighters are often the crusaders who search for and fight evil. Lawful fighters may be defenders who protect the country and people. Disorderly fighters may be wandering mercenaries. Evil fighters often become thugs and little villains, directly using violence to take what they want.</p>

          <p><b>Religion:</b>Fighters often worship the heroic god Hyronis, the power god Cord, the sacred Cuthbert of punishment, the tyranny god Hextor or the slaughter god Erisnu. The fighter may see himself as a jihadist warrior served by his god, or he may just want to have a prayer object before risking his life again.</p>

          <p><b>Background:</b>Fighters join this profession in various ways. Most of the participants received formal training in the aristocratic army or at least the local militia. Some have been trained in formal colleges. Others are self-taught-not very sophisticated but time-tested. Fighters may take up arms in order to escape the constraints of farmland life, or they may be following a glorious family tradition. There is no special shared identity between fighters. They don’t see themselves as a group or a group of comrades. But those fighters from the same academy, mercenary group, or lord's group have a definite comradeship.</p>

          <p><b>Race:</b>The veterans of certain military organizations of human fighters usually come from relatively ordinary families. Dwarf fighters are usually former elite team members-this well-trained team is responsible for defending the kingdom of the underground dwarves. They are often members of warrior families whose bloodlines can be traced back thousands of years, and they have opponents and allies among other dwarf fighters based on their bloodlines. Elf fighters are often good at using long swords. They are very proud of their ability in sword skills, and eagerly want to show or test it. Orc fighters are often deported, and they have gained enough skills through self-study to win recognition and respect from their own people. Gnomes and halfling fighters usually stay in their homeland as local militias rather than risking around. Half-elves rarely become fighters, but they can engage in sword skills in homage to their elven traditions. The brutal and cruel humanoid race can hardly organize the training necessary to become a true fighter. However, a considerable number of strong and skilled fighters have been produced among the militant hobgoblins.</p>

          <p><b>Relationship with other professions:</b>The fighter is good at fighting alone, but he relies on others for magical support, healing, and reconnaissance. In the team, his duty is to stand on the front line to protect the other members of the team, and knock down those tough opponents. The fighters do not understand the mysterious ways of wizards, nor do they have the faith of priests, but they recognize the value of collective cooperation.</p>`
        },
        {
          avatar: require('./assets/monk.png'),
          name: 'Monk',
          fold: 0,
          intro: `<p>The monasteries are scattered in beautiful places, and the monks live in the surrounding cloisters. These monks pursue personal perfection through practice. They are not trained like fighters, but they train themselves to fight unarmed without weapons or armor. Even when they are preparing for battle, they only wear ordinary clothes, and have the ability to come and go unnoticed between rampant thieves and corrupt nobles. A monastery of the good camp is the protector of the local people. Correspondingly, the monasteries of the evil camp use the power of fear to dominate the surrounding land. Evil monks can often become ideal spies, infiltrators and assassins. But as individuals, monks are not interested in supporting those in power and accumulating wealth. They are more concerned about their own physical practice and personal perfection.</p>

          <p><b>Adventure:</b>The monks always regard adventure as their own practice. With a tendency to show off, the monks are happy to show off their fighting arts. The monks are not greedy for wealth, but they are very enthusiastic about ways to perfect their fighting arts. They don't care much about worldly things. Bijing's goal is to achieve self-perfection, not to care about other people's nosy. But they are still very reliable companions.</p>

          <p><b>Traits:</b>The most distinguishing feature of the monk is that he never uses weapons or armor. But with his training, he can fight like a warrior with weapons, and has a faster speed.

          Although the monks never learn spells, they have unique skills. This skill, called ki, enables them to produce amazing power. The monk’s most famous skill is that he can pass by a storm like a storm with his bare hands, knocking out opponents protected by armor.
          
          The monk also has supernatural consciousness, even if he does not observe the attack, he can still avoid it.
          
           As the monks' practice grows, he will be able to exert greater ki and thus generate greater power.</p>

          <p><b>Camp:</b>Because the training of monks requires strict discipline, monks must be in the lawful camp.</p>

          <p><b>Religion:</b>The practice of monks is mainly spiritual. He has a mysterious connection directly with the spiritual world. He does not need pastors and gods.</p>

          <p><b>Background:</b>The monks always concentrate on the typical training in the monastery. When they joined the monastery, most of the monks were still children. The monks all admit to each other, but they also like to compare each other to see whose ki is the strongest.</p>

          <p><b>Race:</b>The monastery is mainly looking for new monks among the people who accept their culture. Therefore, most monks are humans (or half-orcs, half-elves and other races living in human society).</p>

          <p><b>Relationship with other professions:</b>Because the elves can make sincere and long-term dedication, some of them can become monks. But the traditions of dwarves and dwarves are in opposition to the culture of monks. Halflings are too active to become monks. Therefore, it is very rare to see monks of dwarves, dwarves, and halflings.</p>`
        },
        {
          avatar: require('./assets/paladin.png'),
          name: 'Paladin',
          fold: 0,
          intro: `<p>Pursuing goodness, upholding the law, and eliminating evil are the three weapons of a paladin. Few people have the purity and love necessary to become a paladin, but the paladin has been rewarded for it, in terms of treatment, protection, and combat. Those who live in the shadows of scheming wizards, evil monks, blood-eating dragons, and hell mad demons, paladins are their ultimate hope.

          Paladins can work well with kind or lawful priests, and they always support and praise their brave and honest actions. Due to their charisma, trustworthiness and respect, a paladin is always a good team leader.</p>

          <p><b>Adventure:</b>The paladins seriously called their adventure "exploration." In the mind of a paladin, exploration is a good opportunity to prove bravery, develop skills, and learn tactics. The real exploration comes from fighting against powerful evil, not searching for loot in the ruins.</p>

          <p><b>Traits:</b>The power of holiness protects the paladin and gives him special abilities: from improving attributes, resisting damage, never getting sick, healing himself to avoiding fear. In the same way, paladins can heal the wounds of others or treat the diseases of others. In the end, the paladin will use this power to destroy evil. Even the most inexperienced paladin can detect evil, and the experienced paladin can defeat evil and dispel the undead.</p>

          <p><b>Camp:</b>The paladin must be a lawful and kind-hearted camp. Otherwise, the protection of holy power will be lost.</p>

          <p><b>Religion:</b>Paladins do not believe in a certain deity, they are more inclined to dedicate themselves to their purity and justice. Many paladins believe in Heironeous (the god of courage), and many paladins believe in Pelor (the sun god). The paladins who express their faith in the gods are strict and serious in fulfilling their religious duties, so they are very popular in temples.</p>

          <p><b>Background:</b>Becoming a paladin is more than just an option. To become a paladin is to accept a destiny and respond to a call. Most paladins have been summoned and trained since they were young. Their typical experience is to become an experienced paladin’s assistant or follower, and finally set off for the benefit and legal cause. Other paladins were summoned after experiencing some other pursuits in their lives.

          All paladins recognize each other, even two paladins from opposite sides of the world regard each other as comrades and friends. This identity transcends culture, background, race, and even religion.</p>

          <p><b>Race:</b>Humans are the most suitable race to become paladins. Half-elves often find themselves being summoned. Some dwarves can become paladins because it is very difficult for dwarves to put the duties of a paladin before the duties to relatives, clans, and kings. Elf paladins are even rarer, and these elves who have accepted the call often follow their mission and stay away from their birthplace. Few of the other races became paladins.</p>`
        },
        {
          avatar: require('./assets/ranger.png'),
          name: 'Ranger',
          fold: 0,
          intro: `<p>The forest is home to fierce and cunning creatures, but the braver and cunning than them is the ranger. They are all skilled hunters and rangers. The ranger is as familiar with the forest as his home.</p>

          <p><b>Adventure:</b>The ranger is usually the protector of the forest where he is. They often set out on adventures in order to find what destroys the forest, and look for opportunities to destroy that thing.</p>

          <p><b>Traits:</b>The ranger can use all simple and military weapons to fight, and his skills can help him find food and avoid enemies in the wilderness. He has a special understanding of a particular kind of creature, and this knowledge can help him find and defeat this creature more easily. In the end, a ranger can master the power of nature, just as a druid would. An experienced ranger can have the help of one or more animal companions.</p>

          <p><b>Camp:</b>Rangers can be of all factions. Most rangers are in the good camp. They are the protectors of a wilderness area. They are often dedicated to finding and chasing dangerous and evil creatures to this wilderness. Rangers are also most of the rebellious camp, and the rules they follow are nature or their hearts. Evil rangers are very rare, but terrifying. They always imitate nature's most ferocious carnivores and nature's display of the weak and the strong.</p>

          <p><b>Religion:</b>The magic of the ranger is from the power of nature. The goddess of the forest and the god of nature are the ones they can most identify with, but they do not necessarily believe in gods.</p>

          <p><b>Background:</b>Although some rangers have special weapons and experiences, and even grow naturally by learning, most rangers still learn general weapons and skills from their teachers. The best student may be the heir to his teacher's name and surname.</p>

          <p><b>Race:</b>Elves are the most common rangers. Their home is in the forest, and they move gracefully and are very quiet. Half-elves who live with their elven parents usually also become rangers. Human beings can adapt to life in the forest due to their strong learning ability, thus becoming a ranger. Half-orcs often find it better to be a ranger than to live among humans or orcs and suffer malformation. There are more dwarf rangers than warriors, but they are more willing to stay in their own kingdom rather than risking between big races. Dwarf Rangers are extremely rare, but they are quite impressive. Because Dwarf Rangers do not live on the surface, they tend to make their homes in large underground caves, and they always use ruthless precision strikes to destroy their enemies. The Dwarf Ranger is also known as the Cave Ranger. The ranger among the halflings only exists in the legend. You may hear countless stories about him, but you will never meet one.</p>

          <p><b>Relationship with other professions:</b>Rangers generally get along well with druids and some berserkers. They often quarrel with paladins, because paladins often share the same goals with rangers, but they are quite different in style, tactics, methods, philosophy, and aesthetic viewpoints.

          Rangers do not count on the support and friendship of other people. They find that they can treat people who are different even if they are mages who gnaw books or priests who love preaching with tolerance.</p>`
        },
        {
          avatar: require('./assets/rouge.png'),
          name: 'Rouge',
          fold: 0,
          intro: `<p>The thieves have very little in common. Some of them are lurking thieves, and some are clever liars—or scouts, sneakers, spies, diplomats, or major criminals. The only thing they passed was versatility, adaptability to various environments, and resourcefulness. Generally speaking, thieves don't like frontal combat. They prefer to lay traps to kill people and make silent raids. Moreover, they use very scary skills: from opening locks to stealing other people's money.</p>

          <p><b>Adventure:</b>Thieves often take risks for the items or experiences they want, and sometimes for the fun of challenges. Designing traps and avoiding alarms is the biggest entertainment for many thieves.</p>

          <p><b>Traits:</b>The thieves have so many skills that they can only concentrate on practicing some of them. Unlike other professions, a raid by thieves can cause huge damage. When danger comes, thieves have a sixth sense to detect in advance. Although thieves can't cast spells, they can imitate the abilities of mages through magic items such as scrolls and staff.</p>

          <p><b>Camp:</b>The thieves pursue opportunities rather than ideals, and thieves prefer chaos to order, but this does not prevent thieves from being in any camp.</p>

          <p><b>Religion:</b>The thieves are not devout believers, most thieves do not believe in gods, and they usually only accept the god of thieves, the god of death, or the god of slaughter.</p>

          <p><b>Background:</b>Thieves often form guilds, but the most common is to receive education from an independent teacher or practice by themselves. Usually thieves have a second profession to cover up their profession, because thieves often break the law or be caught by others. To sell, they need a kind of space buffer.

          Unless it is the same guild or the same teacher, thieves rarely agree with each other. In fact, after understanding the skills they can master, thieves trust people of other professions more-they are not fools. Thieves like to get the cover of warriors and mages, but more often, thieves prefer to use silence, cunning and patience to defeat their opponents. The only exception is that thieves hate paladins.</p>

          <p><b>Race:</b>With strong adaptability and often without discipline, humans can easily become thieves. Buried in the lives of bad guys. Halflings, elves, and half-elves can also meet the needs of thieves. Dwarves and gnomes are more easily known as experts in unlocking and trapping, and half-orcs are more known for assassinations.</p>`
        },
        {
          avatar: require('./assets/sorcerer.png'),
          name: 'Sorcerer',
          fold: 0,
          intro: `<p>Sorcerer dragon. As we all know, dragons can change into various forms, so they can also produce offspring with humans-but this statement is difficult to verify. Sorcerer often have beautiful appearances and are always somewhat exotic.</p>

          <p><b>Adventure:</b>The most common reason for a wizard to participate in adventures is to improve his own abilities and to test the limits of his own abilities. There are also many Sorcerer who participate in adventures so that the world can affirm their status in society.</p>

          <p><b>Traits:</b>The power of Sorcerer is innate, they don't need formal spell training, but their power is stronger than the wizards learned by the day after tomorrow. Because there is no need to use a lot of time and heavy spell books to prepare spells, they have more time to learn to use simple weapons.</p>

          <p><b>Camp:</b>As a Sorcerer, magic is an intuition rather than a science. Therefore, wizards are more inclined to a free, rebellious, and creative camp.</p>

          <p><b>Religion:</b>Some Sorcerer respect the god of spells, but most wizards do not follow a god or anything.</p>

          <p><b>Background:</b>When they are still young, the magic power in the Sorcerer's body will be awakened. The first spell they cast is often incomplete, spontaneous, random, and often dangerous. It will take a while for the young wizard to understand how to control the mana in his body. Usually awakening wizards are often frightened by their former friends and relatives, thinking that there is an incident such as devil possession.

          Sorcerer have no basis for mutual recognition, and they have never operated like a group.</p>

          <p><b>Race:</b>Most Sorcerer are humans or half-elves. However, the ability of witchcraft is unpredictable, and it may appear in any race.

          Sorcerer often get along better with wizards, druids, or rangers, and often argue with monks and paladins. Since it was discovered that Sorcerer and wizards cast the same spell in different ways, the competition between the two has never stopped.</p>`
        },
        {
          avatar: require('./assets/wizards.png'),
          name: 'Wizards',
          fold: 0,
          intro: `<p>Difficult words, changeable gestures, complex movements, and bizarre spell-casting materials. Through painstaking research, the wizards have finally transformed magic from an incredible art into a professional technique that can be mastered through training. They created the theory of spells and different schools, turning spellcasting into a formula that can calculate the process and predict the result, but the wizards also spent a lot of time on his spell books. In order to create new spells, the wizard We must go through a lot of research and practice. Whenever possible, the mages are always looking up old books, discussing new theories, and experimenting with new spells.

          For the mage, magic is a professional technique that relies on long-term and hard training, rather than an art that is beyond words.</p>

          <p><b>Adventure:</b>The mage will carefully estimate the danger before taking the risk, and carefully prepare the spell. The mages must be prepared before they can use those spells, and in the event of an attack, the mages are very vulnerable. The biggest reason for the mage to participate in the adventure is to find funds for knowledge, strength and research. The mage is happy to work with other professions.</p>

          <p><b>Traits:</b>Unlike the  Sorcerer, the  wizard is a person who has acquired the skills to use spells through hard research and study the day after tomorrow. Many wizards will choose to focus on the study of a certain series of spells, which allows them to cast a little more times and have better results. As a sacrifice, they must abandon the learning of certain spell series, so that they can't use those series at all. Spells. (See the School Specialization section, page 54) As the research and learning of spells takes time, so mages can only use very simple weapons (such as daggers and sticks). The number of spells that a mages can cast per day is higher than that of bards, paladins, etc. There are slightly more informal spell users. After spending some time reading the heavy spell books, you can use it after you are ready to complete the specified spells.</p>

          <p><b>Camp:</b>Mages generally respect the gods of magic, and the necromancers of the necromancer are more inclined to the gods of death.</p>

          <p><b>Religion:</b>Some wizards respect the god of spells, but most wizards do not follow a god or anything.</p>

          <p><b>Background:</b>Humans learn spells for various reasons: curiosity, ambition, longing for power, or just personal hobbies. Human wizards are the innovators of spells, from creating new spells to creatively using old spells. Elves love spells very much, but they regard spells more as an art, which is different from humans who regard spells as practical tools. For gnomes, only the illusionist's illusion is the technology they will accept, and they can often make the illusionist's illusion more dazzling and colorful. Half-elves often feel the love of elves for spells and the control of spells by humans at the same time. Most of the most powerful wizards are from the camp of half-elves. The society of dwarves and halflings discourages the learning of spells, and half-orcs rarely have enough intelligence to understand spells, so there are very few wizards of these races. It is worth noting that the dark elves Drow are all natural wizards, but their abilities are very ordinary.</p>

          <p><b>Race:</b>The mages are happy to work with other professions. They like to hide behind the strong warriors, let the thieves go to the front to scout, and enjoy the protection of the priest's magic: the survival of a normal mage adventurous may be the highest.</p>`
        },
    ],
    attr: [
      {
        title: 'Strength，STR',
        fold: 0,
        desc: `
        <p>Strength quantifies the character's muscle and physical strength. This attribute is especially important for warriors, barbarians, paladins, rangers, and monks. It can help them win in battle. Strength also determines the upper limit of the character's weight.<p>

        <p>The character's power adjustment value will be used for: Melee attack hit dice. </p>

        <p>Damage rolls when using melee weapons or throwing weapons (including slings). (Special case: non-dominant hand only has half of the character's power bonus, while two-handed weapons gain 1.5 times the power bonus. The power penalty (not the power bonus) will be applied to attacks made by non-composite bows.)</p> 

        <p>Climb, jump, and swim checks. These skills have strength as a key attribute.</p>

        <p>Strength checks (such as breaking a door, etc.).</p>
        `
      },
      {
        title: 'Dexterity，DEX',
        fold: 0,
        desc: `
        <p>Agility quantifies hand-eye coordination, flexibility, reflex and balance. This attribute is the most important attribute for rogues, but for those who usually wear light or medium armor (rangers and barbarians), or without armor (monks, wizards, and warlocks), and anyone who wants to be a good archer For the characters, agility is also very important.<p>

        <p>The character's agility adjustment value will affect the following actions, skills, etc.: </p>

        <p>Long-range attack hit dice, including bow, crossbow, throwing axe and other long-range weapon attacks.</p> 

        <p>The Armor Class (AC) ensures that the character can respond to attacks.</p>

        <p>With a reflex save, you can avoid fireball and other attacks by moving quickly.</p>
        <p>Balance, escape, concealment, stealth, unlock, riding, hand skill, roll and rope skills checks. These skills have agility as a key attribute.</p>
        `
      },
      {
        title: 'Constitution，CON',
        fold: 0,
        desc: `
        <p>Physique expresses the character's health and endurance. The Constitution bonus can increase the life points of a character, so it is important for all classes.<p>

        <p>The physique adjustment value of the character will be used for: </p>

        <p>Every Hit Dice is thrown (but the Constitution penalty can never make the result lower than 1—that is, the character can gain at least one HP when leveling up).

        Fortitude saving throws are used to resist toxins and similar threats.</p> 

        <p>Focus on the test. Concentration is a very important skill for the caster, with physique as its key attribute. If the character's constitution value changes enough to make its constitution correction change, its health value will increase or decrease accordingly.</p>
        `
      },
      {
        title: 'Intelligence，INT',
        fold: 0,
        desc: `
        <p>Intelligence determines the character's ability to learn and reason. This attribute is very important for the wizard, because it determines the number of spells that the wizard can cast, the difficulty of resisting them to cast the spell, and the power of the spell. At the same time intelligence is important for anyone who wants to have multiple skills.<p>

        <p>The character’s intelligence adjustment value will be used for:  The number of languages mastered at the beginning of the game.</p>

        <p>The number of skill points gained per level. (But the character can get at least 1 skill point per level.)</p> 

        <p>Valuation, craftsmanship, document interpretation, dismantling devices, forged documents, knowledge, search, and spell recognition checks. These skills have intelligence as a key attribute. The mage is rewarded with the number of spells cast by the high intelligence value. The minimum intelligence required to cast a wizard spell is 10 + the spell level. The animal's intelligence value is 1 or 2. Humanoids are at least 3.</p>
        `
      },
      {
        title: 'Charisma，CHA',
        fold: 0,
        desc: `
        <p>Charisma represents the character's courage, persuasiveness, personal attractiveness, leadership ability and the natural charm of the body. This attribute is the most important for paladins, warlocks, and bards. It is also important to priests because it affects the priest's ability to repel undead creatures. Any creature has charm.<p>

        <p>The charisma adjustment value of the character will be used for: </p>

        <p>Bluff, negotiate, disguise, collect information, domesticate animals, intimidate, perform and use magic device checks. These skills have charisma as a key attribute.

        Check for actions that attempt to influence others</p> 

        <p>Priest and paladin’s dispel checks are used to dispel zombies, vampires, and other undead creatures.</p>

        <p>Warlocks or bards are rewarded for the number of spells cast by the high Charisma value. The minimum Charisma value required to cast a sorcerer or poet spell is 10 + the spell level.</p>
        `
      }

    ]
}